body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@import 'antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  font-size: calc(10px + 2vmin);
}

/** 页面顶部搜索栏样式 */
.search {
  margin: 10px;
  box-sizing: border-box;
  padding: 1.25rem 20px 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.search .ant-form-item {
  margin-bottom: 20px;
}


/** 表格样式 */
.table-wrapper {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin: 10px;
}

.table {
  flex: 1;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 48.5px;
}

.actions span {
  cursor: pointer;
}

.primary {
  color: #2276d0;
}

.warning {
  color: #d4510f;
}

.error {
  color: red;
}

.pd-5 {
  padding: 1.25rem;
}

.marker {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url('./assets/icons/collection-red.svg');
  background-size: cover;
  border-radius: 50%;
  transition: background-image 0.3s;
}

.marker:hover {
  background-image: url('./assets/icons/collection-blue.svg');
}

.video:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

.video:-webkit-full-screen iframe {
  width: 100vw !important;
  height: 100vh !important;
}


