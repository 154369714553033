.page{
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url("/src/assets/images/login-bg.png");
    color:#fff;
    -webkit-user-select:none;
    -moz-user-select:none;
    -o-user-select:none;
}
.midBg{
    width: 72.5%;
    min-height: 745px;
    height: 69%;
    background-size: 100% 100%;
    background-image: url("/src/assets/images/login-bg-2.png");
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loginBox{
    width: 391px;
    height: 325px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: 100% 100%;
    background-image: url("/src/assets/images/login-box.png");
}
.loginText{
    width: 44px;
    height: 29px;
    font-size: 22px;
    font-weight: 400;
    line-height: 29px;
    color: #FFFFFF;
    margin-top: 28px;
}
.inputBox{
    width: 290px;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #26C2E3;
    border-radius: 4px;
    background: rgba(32, 78, 149, 0.9);
    margin-top: 27px;
}
.icon{
    width: 14px;
    height: 14px;
    margin-left: 16px;
}
.accountBox{
    height: 42px;
    margin-bottom: 0;
    align-items: center;
    display: flex;
}
.input{
    color: #FFFFFF;
    margin-left: 10px;
    margin-top: 20px;
}
.rememberPasswordBox{
    height: 16px;
    width: 290px;
    /*background:red;*/
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

}
.selectBox{
    width: 12px;
    height: 12px;
    cursor: pointer;
}
.rememberText{
    margin-left: 8px;
    width: 48px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #D2D9E9;
    cursor: pointer;
}
.loginButton{
    margin-top: 34px;
    width: 290px;
    height: 40px;
    background: linear-gradient(180deg, #00B6FE 0%, #017DE6 100%);
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.loginButton:active{
    background: linear-gradient(180deg, rgba(0, 182, 254, 0.8) 0%, rgba(1, 125, 230, 0.8) 100%);
    color: rgba(255, 255, 255,0.6);
}
