.header {
  position: sticky;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 22px;
  margin-left: 240px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.headerRight {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.time {
  margin: 0 50px 0 10px;
}

.username {
  margin-left: 10px;
  font-weight: 600;
}

.back {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.backText {
  margin-left: 6px;
}

.justifyEnd {
  justify-content: flex-end;
}
