.container {
  position: fixed;
  position: sticky;
  top: 0;
  width: 220px;
  height: 100vh;
  overflow-y: auto;
  background: #242e3e;
  border-radius: 0 10px 10px 0;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.avatar {
  width: 80px;
  height: 80px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.1);
}

.name {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.8);
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.nav-active {
  font-weight: 500;
  color: #fff;
  /*background: center/cover url('../assets/images/选中@2x.png');*/
}

.icon {
  margin-right: 6px;
}

.logoWrapper {
  margin: 30px 16px 30px;
  font-size: 1.5em;
  line-height: 40px;
  text-align: center;
}

.logo {
  width: 197px;
  height: 53px;
}
