.loginBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.ctlBox {
    display: flex;
    flex-direction: column;
    //align-items: center;
}

.mainBox {
    display: flex;
}


.leftBox {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    margin-right: 40px;

    div {
        display: flex;
    }
}


.rightBox {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;

    div{
        display: flex;
        margin-top: auto;
    }


    .rightBox1 {
        justify-content: center;
        margin: 0;
    }


    .rightBox3 {
        justify-content: center;
    }
}

.ctl{
    display: flex;
    align-items: center;
    justify-content: center;
}

.alertButtonBox{
    //width: 270px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}