.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  margin-left: 240px;
  background: #f3f6f6;
}

.container > div {
  flex-grow: 1;
}
