.page{
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url("/src/assets/images/login-bg.png");
    color:#fff
}
input{
    background:none;
    outline:none;
    border:none;
}
input:focus{
    border:none;
}
.accountBox .ant-form-item{
    margin-bottom: 0;
}
.page  .login{
    width: 420px;
    display: flex;
    flex-direction: column;
}
.text{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 1;
}
.accountBox{
    width: 99%;
    height: 46px;
    opacity: 0.6;
    background: #102966;
    border: 1px solid #3156c0;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.accountBox .icon{
    width: 14px;
    height: 14px;
    margin-left: 20px;
}
.accountBox .accountBoxInput{
    margin-left: 9px;
    height: 46px;
    background: none;
    color: #fff;
    width: 94%;
    font-size: 16px;
}

.checkBox{
    width: 15px;
    height: 15px;
    opacity: 1;
    background: #485c9b;
}
.button{
    margin-top: 44px;
    width: 99%;
    height: 46px;
    opacity: 1;
    background: #4d79f6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
}
.button:active{
    background: #6D93FE;
}
